<template>
  <WeContainer header="">
    <h5 class="custom-card-title">
      <i class="fas fa-receipt"></i>
      <span>{{ $t("invoiceIntegrationList") }}</span>
    </h5>
    <hr />
    <WeTable
      v-if="ready"
      v-bind:index="false"
      v-bind:allow-sort-index="false"
      v-bind:data="invoiceIntegration.list"
      v-bind:columns="columns"
      v-bind:actions="actions"
      v-on:on-action="onAction"
      v-on:on-switch="onSwitch"
    />
    <div v-else class="card-body position-relative" style="height: 250px">
      <WeLoading />
    </div>
    <WeModal
      v-if="showModal && invoiceDetail"
      v-bind:title="invoiceDetail.title + ' Entegrasyon Ayarları'"
      v-on:close="clearItem"
    >
      <div slot="body">
        <template v-if="!modalLoading">
          <div
            class="custom-scrollbar pl-1 pr-3"
            style="max-height: 400px; overflow: auto"
          >
            <WeCard class="bg-light mb-3">
              <div class="row justify-content-around">
                <div class="col-12 col-md-auto">
                  <label class="custom-label">Durum</label>
                  <WeSwitch
                    v-bind:show-status="true"
                    v-model="invoiceDetail.is_active"
                  />
                </div>
                <div class="col-12 col-md-auto">
                  <label class="custom-label">Test Modu</label>
                  <WeSwitch
                    v-bind:show-status="true"
                    v-model="invoiceDetail.is_test"
                  />
                </div>
                <div class="col-12 col-md-auto">
                  <label class="custom-label">Varsayılan</label>
                  <WeSwitch
                    v-bind:show-status="true"
                    v-model="invoiceDetail.is_default"
                  />
                </div>
              </div>
            </WeCard>
            <template v-for="invoiceEnv in invoiceDetail.environments">
              <div
                class="col-12 mb-3 col-lg p-0"
                v-for="item in invoiceEnv.elements"
                v-bind:key="invoiceEnv.name + '-' + item.name"
              >
                <WeRowInput
                  v-if="
                    (invoiceEnv.name == 'demo' && invoiceDetail.is_test) ||
                    (invoiceEnv.name == 'canli' && !invoiceDetail.is_test)
                  "
                  v-bind:class="getClass(item)"
                  v-bind:label="item.label"
                  v-bind:form="item.form"
                  v-bind:placeholder="item.placeholder || item.label"
                  v-bind:show-asterix="item.required"
                  size="sm"
                  v-bind:value="getValue(item)"
                  v-on:input="item.value = $event"
                />
              </div>
            </template>
          </div>
          <div class="text-center mt-3">
            <WeSubmitButton
              v-bind:submit-status="submit"
              v-on:submit="onSubmit"
            />
          </div>
        </template>
        <template v-else>
          <div style="min-height: 250px; overflow: auto">
            <WeLoading />
          </div>
        </template>
      </div>
    </WeModal>
  </WeContainer>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "InvoiceIntegration",
  data() {
    return {
      submit: false,
      ready: false,
      showModal: false,
      modalLoading: false,
      invoiceDetail: null,
      columns: [
        {
          name: "logo",
          icon: "fas fa-camera",
          type: "image_url",
          width: "5%",
        },
        { name: "title", th: "Firma Adı", type: "string" },
        { name: "is_default", th: "Varsayılan", type: "boolean", width: "10%" },
        { name: "is_active", th: "Durum", type: "boolean", width: "10%" },
      ],
      actions: [
        {
          icon: "fas fa-edit",
          class: "btn-outline-primary",
          action: "edit",
        },
      ],
    };
  },
  methods: {
    ...mapActions("invoiceIntegration", [
      "getList",
      "getById",
      "isActive",
      "isDefault",
      "update",
    ]),
    /**
     * Tablo İşlemleri
     */
    onAction(data) {
      switch (data.key) {
        case "edit":
          this.editInvoice(data.row);
          break;
      }
    },
    clearItem() {
      this.showModal = false;
      this.invoiceDetail = null;
      this.getIntegrationList();
    },
    onSubmit() {
      // if (!this.isFormValid()) return;

      this.submit = true;
      this.update({
        invoice: this.invoiceDetail,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.$toast.success("Kayıt Başarılı");
            this.clearItem();
          } else {
            this.$toast.error("Hatalı İşlem");
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    isFormValid() {
      let result = false; /*this.invoiceDetail.environments.filter((invoiceEnv) => {
        return invoiceEnv.elements.filter(
          (item) => item.required && !item.value
        ).length;
      }).length;*/

      if (result) {
        this.$toast.error("Lütfen zorunlu alanları doldurun");
        return false;
      } else {
        return true;
      }
    },
    /**
     * Entegrasyon düzenle,
     * Bilgileri invoiceDetail objesine kaydet
     */
    editInvoice(row) {
      const checkPermission = this.checkPermission("u");
      if (!checkPermission) {
        permission.alert(this);
      } else {
        if (row.id) {
          this.modalLoading = true;
          this.showModal = true; // Modal Göster
          this.invoiceDetail = { ...row };
          this.getById({
            id: row.id,
            onSuccess: (result) => {
              this.invoiceDetail = result;
            },
            onFinish: () => {
              this.modalLoading = false;
            },
          });
        }
      }
    },
    getIntegrationList() {
      const checkPermission = this.checkPermission("v");
      if (!checkPermission) {
        permission.alert(this);
      } else {
        this.ready = false;
        this.getList({
          onFinish: (result) => {
            this.ready = true;
          },
        });
      }
    },
    onSwitch(data) {
      const checkPermission = this.checkPermission("u");
      if (!checkPermission) {
        permission.alert(this);
      } else {
        if (data.column == "is_default") {
          this.isDefault({
            form: data,
            onSuccess: (result) => {
              if (result && result.data && result.data.status !== "error") {
                this.$toast.success("Varsayılan Durumu Güncellendi");

                if (data.is_active) {
                  this.invoiceIntegration.list
                    .filter((item) => item.id !== data.id)
                    .map((item) => {
                      item.is_default = 0;
                    });
                }
              } else {
                this.$toast.error("Varsayılan Durumu Güncellenemedi");
              }
            },
            onError: (error) => {
              this.$toast.error("Varsayılan Durumu Güncellenemedi");
            },
            onFinish: () => {
              this.pos.list.sort();
            },
          });
        } else {
          this.isActive({
            form: data,
            onSuccess: (result) => {
              if (result && result.data && result.data.status !== "error") {
                this.$toast.success("Durum Güncellendi");
              } else {
                this.$toast.error("Durum Güncellenemedi");
              }
            },
            onError: (error) => {
              this.$toast.error("Durum Güncellenemedi");
            },
          });
        }
      }
    },
    checkPermission(code) {
      return permission.check("invoiceIntegration", code);
    },
    getParent(parentName) {
      return this.invoiceDetail.elements.find(
        (element) => parentName === element.name
      );
    },
    getValue(item) {
      let result = item.value;

      if (item.hasOwnProperty("parent")) {
        const parentItem = this.getParent(item.parent);

        if (result) {
          parentItem.value = true;
        }
      }

      return result;
    },
    getClass(item) {
      if (item.hasOwnProperty("parent")) {
        const parentItem = this.getParent(item.parent);

        if (parentItem.form == "boolean") {
          if (!parentItem.value) {
            item.value = null;
            return "d-none";
          }
        }
      }
    },
  },
  computed: {
    ...mapState(["invoiceIntegration"]),
  },
  mounted() {
    this.getIntegrationList();
  },
};
</script>